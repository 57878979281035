@use "styles/mixins/setFonts" as *;
@use "styles/variables/colors/colors";

.review {
  display: flex;
  justify-content: center;
  background-color: colors.$sectionPrimaryBg;

  &__container {
    max-width: 1140px;
    width: 100%;
    padding: min(95px, 8%) 15px min(140px, 12%);
    display: flex;
    align-items: center;
  }

  &__info {
    max-width: 700px;
  }

  &__header {
    @include setFonts("Ubuntu", 1rem, 1.5rem, 400);
    letter-spacing: 0.33em;
    text-transform: uppercase;
    color: colors.$blackTextColor;
    margin-bottom: 17px;
  }

  &__text {
    @include setFonts("Ubuntu", 1.5rem, 2rem, 700);
    color: colors.$blackTextColor;
    margin-bottom: 30px;
  }

  &__name {
    @include setFonts("Ubuntu", 1rem, 1.5rem, 700);
    color: #6e6c6c;
  }
}

.review__img-wrapper {
  max-width: 420px;
  width: 100%;
  * {
    width: 100%!important;
  }
}

@media screen and (max-width: 1024px) {
  .review__container {
    flex-direction: column;
  }
}

@media screen and (max-width: 640px) {
  .review {
    &__header-wrapper {
      display: flex;
      justify-content: center;
    }

    &__header {
      color: #fff;
      background: #008060;
      border-radius: 3px;
      padding: 4px 15px;
    }

    &__text {
      text-align: center;
    }

    &__footer {
      display: flex;
      justify-content: center;
    }

    &__container {
      justify-content: center;
    }
  }
}